import React, { useRef } from 'react'
import cx from 'classnames'
import Button from '../button/button'

import './card.sass'
import HoverAnimation from '../hoverAnimation/hoverAnimation'

interface CardProps {
  heading: string
  name: string
  description?: string
  button: string
  href?: string
  color?: 'primary' | 'yellow' | 'cyan' | 'white'
  size?: 'regular' | 'large'
  flip?: boolean
  textInverse?: boolean
  image?: React.ReactNode
  imageHover?: boolean
  content?: React.ReactNode
  animation?: string
  animationAutoplay?: boolean
}

const Card = ({
  heading,
  name,
  button,
  description,
  image,
  color = 'primary',
  size = 'regular',
  flip = false,
  textInverse,
  imageHover,
  content,
  animation,
  animationAutoplay = true,
  href = '',
}: CardProps) => {
  const containerRef = useRef<HTMLElement>(null)

  return (
    <div
      role="presentation"
      className={cx('card-container', `card-container--${size}`)}
    >
      <a
        href={href}
        className={cx(
          'card',
          `card--${color}`,
          `card--${size}`,
          `${flip ? 'card--flipped' : ''}`
        )}
        target="_blank"
        rel="noreferrer noopener"
        ref={containerRef}
      >
        <div
          className={cx('card__image-wrapper', {
            'card__image-wrapper--animation': !!animation,
            'card__image-wrapper--image-hover-scale': imageHover,
            'card__image-wrapper--content': !!content,
            'card__image-wrapper--inverse': !!textInverse,
          })}
        >
          {animation && (
            <HoverAnimation
              animation={animation}
              autoplay={animationAutoplay}
              containerRef={containerRef}
            />
          )}
          {image}
          {content}
        </div>
        <div className="card__gap"></div>
        <div
          className={cx('card__content-wrapper', {
            'card__content-wrapper--inverse': textInverse,
          })}
        >
          <div
            className={cx('card__heading', {
              'card__heading--inverse': textInverse,
            })}
          >
            {heading}
          </div>
          <div
            className={cx('card__name', {
              'card__name--inverse': textInverse,
            })}
          >
            {name}
          </div>
          {description && (
            <div
              className={cx('card__description', {
                'card__description--inverse': textInverse,
              })}
            >
              {description}
            </div>
          )}
          <Button
            className={cx('card__button', {
              'card__button--inverse': textInverse,
            })}
            color={color}
          >
            {button}
          </Button>
        </div>
      </a>
    </div>
  )
}
export default Card
