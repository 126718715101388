import React, { useContext } from 'react'

import { GeoContext } from '../state/geo'

import Banner from '../components/banner/banner'
import Card from '../components/card/card'
import Container from '../components/container/container'
import Layout from '../layout/layout'

import awardGIA from '../assets/images/award_gia.png'
import awardDDA from '../assets/images/award_dda.png'
import uxstrategyImg from '../assets/images/uxstrategy.svg'
import teamsImg from '../assets/images/teams.svg'
import eyeAnimation from '../assets/animations/eye.json'
import softwareHubAnimation from '../assets/animations/softwarehub.json'
import CardsGrid from '../components/cardsGrid/cardsGrid'

const IndexPage = () => {
  const geo = useContext(GeoContext)

  return (
    <Layout withHeader={false}>
      <Banner />
      <Container>
        <CardsGrid>
          <Card
            heading="IDEATE AND PLAN"
            name="UX Strategy Kit"
            description="Kickstart your ideation 
        session or digital product 
        development sprint."
            button="Explore the UX&nbsp;Strategy&nbsp;Kit"
            size="large"
            animation={eyeAnimation}
            href={`https://uxstrategykit.${geo?.isUS ? 'emd' : 'merck'}.design`}
          />
          <Card
            heading="CREATE TOGETHER WITH US"
            name="UX Strategy&nbsp;& Design&nbsp;Team"
            button="Enter UXSD.io"
            href="https://uxsd.io"
            imageHover={true}
            image={
              <img
                src={uxstrategyImg}
                alt=""
                style={{ alignSelf: 'flex-end' }}
              />
            }
          />
          <Card
            heading="GET SUPPORT"
            name="Find us on MS Teams"
            button="Join our Channel"
            color="yellow"
            imageHover={true}
            image={
              <img
                src={teamsImg}
                alt=""
                style={{ height: '60px', alignSelf: 'center' }}
              />
            }
            href="https://teams.microsoft.com/l/team/19%3aeae3b35b0cbf42659e45c2b5592e0c0e%40thread.tacv2/conversations?groupId=88f23881-53e2-4a99-ad5c-8188c1087bbf&tenantId=db76fb59-a377-4120-bc54-59dead7d39c9"
          />
          <Card
            heading="SOFTWARE HUB"
            name="Bringing digital together"
            description={`Let us celebrate digital
            products at ${geo?.isUS ? 'EMD' : 'Merck'}.`}
            button="Visit Software Hub"
            size="large"
            color="cyan"
            flip={true}
            animation={softwareHubAnimation}
            animationAutoplay={false}
            href={`https://apps.${geo?.isUS ? 'emd' : 'merck'}.design`}
          />
          <Card
            heading="AWARDS"
            name="Awards for eyrise®"
            description={`Together with eyrise® we won three popular and highly renowned awards.`}
            button="Visit eyrise®"
            color="white"
            size="large"
            textInverse={true}
            content={
              <div>
                <a
                  href="https://www.german-innovation-award.de/en/winners/preis/gewinner/eyriser-lightwellness-app/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={awardGIA}
                    alt="German Innovation Award"
                    style={{ height: '56px' }}
                  ></img>
                </a>
                <a
                  href="https://www.german-innovation-award.de/en/winners/preis/gewinner/eyriser-lightwellness-app-1/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={awardGIA}
                    alt="German Innovation Award"
                    style={{ height: '56px' }}
                  ></img>
                </a>
                <a
                  href="https://gewinner.deutscherdigitalaward.de/gewinner/mobile-apps-idee-innovation-bronze/eyrise-digital-experience"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={awardDDA}
                    alt="Deutscher Digital Award"
                    style={{ height: '58px' }}
                  ></img>
                </a>
              </div>
            }
            href="https://www.eyrise.com/"
          />
        </CardsGrid>
      </Container>
    </Layout>
  )
}

export default IndexPage
