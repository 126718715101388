import React, { useEffect, useRef, useState } from 'react'

import lottie, { AnimationItem } from 'lottie-web'

interface HoverAnimationProps {
  animation: string
  height?: number
  width?: number
  style?: any
  loop?: boolean
  autoplay?: boolean
  containerRef: React.RefObject<HTMLElement>
}

const HoverAnimation = ({
  animation,
  style = {},
  loop = false,
  autoplay = true,
  containerRef,
}: HoverAnimationProps) => {
  const [animationItem, setAnimationItem] = useState<
    AnimationItem | undefined
  >()
  const animationContainer = useRef<HTMLElement>(null)

  useEffect(() => {
    if (!animationContainer.current || !containerRef.current) return

    let anim = animationItem
    if (!anim) {
      anim = lottie.loadAnimation({
        container: animationContainer.current,
        renderer: 'svg',
        loop,
        autoplay,
        animationData: animation,
      })
      setAnimationItem(anim)
    }

    const handleMouseEnter = () => anim.play()
    const handleMouseLeave = () => anim.stop()

    containerRef.current.addEventListener('mouseenter', handleMouseEnter)
    containerRef.current.addEventListener('mouseleave', handleMouseLeave)

    return () => {
      containerRef.current?.removeEventListener('mouseenter', handleMouseEnter)
      containerRef.current?.removeEventListener('mouseleave', handleMouseLeave)
    }
  }, [animationContainer.current])

  return <div style={{ ...style }} ref={animationContainer} />
}

export default HoverAnimation
