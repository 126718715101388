import React from 'react'
import cx from 'classnames'
import './button.sass'

interface ButtonProps {
  children: React.ReactNode
  className?: string
  color?: 'primary' | 'yellow' | 'white' | 'cyan'
  size?: 'regular' | 'large'
  tag?: keyof JSX.IntrinsicElements
  props?: any
}

const Button = ({
  tag: El = 'button',
  children,
  className,
  color = 'primary',
  size = 'regular',
  props = {},
}: ButtonProps) => (
  <El
    className={cx('button', className, `button--${color}`, `button--${size}`)}
    {...props}
  >
    {children}
  </El>
)

export default Button
