import React from 'react'
import './cardsGrid.sass'

interface CardsGridProps {
  children: React.ReactChildren
}

const CardsGrid = ({ children }: CardsGridProps) => (
  <div className="cards-grid">{children}</div>
)

export default CardsGrid
