import React, { useContext } from 'react'
import { GeoContext } from '../../state/geo'
import Button from '../button/button'
import Container from '../container/container'
import Logo from '../logo/logo'
import './banner.sass'
import bannerPattern from '../../assets/images/banner_pattern.svg'
import bannerImage from '../../assets/images/banner_image.png'
import bannerAwardImage from '../../assets/images/award_card_gba.png'

const Banner = () => {
  const geo = useContext(GeoContext)
  return (
    <div className="banner">
      <Container className="banner__container">
        <div className="banner__pattern"></div>
        <Logo />
        <div className="banner__content">
          <div className="banner__heading">
            Meet the most&nbsp;scientific
            <br />
            Design System.
          </div>
          <Button
            tag="a"
            props={{
              href:
                geo?.brand === 'Merck'
                  ? 'https://liquid.merck.design/'
                  : 'https://liquid.emd.design/',
              target: '_blank',
              rel: 'noreferrer',
            }}
            size="large"
            className="banner__button"
          >
            Get Liquid Design System
          </Button>
        </div>

        <img src={bannerImage} className="banner__image" />
        <a
          href="https://www.german-brand-award.com/preistraeger/galerie/detail/37307-liquid-design-system.html"
          target="_blank"
          rel="noopener noreferrer"
          className="banner__award-image"
        >
          <img alt="German Brand Award" src={bannerAwardImage} />
        </a>

        {/* <div className="banner__background">
          
        </div> */}
      </Container>
    </div>
  )
}

export default Banner
